//Fix for normalizecss so that tailwind borders don't get removed on buttons if they are specified.

button {
    border: inherit;
    border-width: 0;
}

.web-scroll-container * {
    &::-webkit-scrollbar {
        width: 6px;
        height: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background: #bcbcbc;
        cursor: pointer;
        border-radius: 3px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
}

.dark .web-scroll-container * {
    &::-webkit-scrollbar-thumb {
        background: #444;
        cursor: pointer;
        border-radius: 3px;
    }
}

@font-face {
    font-family: 'Open Sans';
    src: url('./assets/fonts/Open_Sans/OpenSans-VariableFont_wdth,wght.ttf') format('truetype');
    font-weight: 100 900;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./assets/fonts/Open_Sans/OpenSans-Italic-VariableFont_wdth,wght.ttf') format('truetype');
    font-weight: 100 900;
    font-style: italic;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('./assets/fonts/Bebas_Neue/BebasNeue-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        @apply font-open-sans;
    }

    * {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    html {
        @apply base-font-size;
        font-family: unset;
    }
}

@layer components {
    .base-font-size {
        @apply portrait:text-base landscape:text-sm landscape:xxs:text-xs portrait:xxs:text-sm landscape:xs:text-sm portrait:xs:text-base landscape:sm:text-base portrait:sm:text-lg landscape:md:text-lg portrait:md:text-xl landscape:lg:text-xl;
    }

    .web-font-size {
        @apply portrait:text-base landscape:text-sm landscape:xxs:text-xs portrait:xxs:text-sm landscape:xs:text-sm portrait:xs:text-base landscape:sm:text-base portrait:sm:text-lg landscape:md:text-lg;
    }

    ion-title {
        @apply text-[1.438rem] landscape:md:leading-none pt-[0.1rem];
    }

    ion-toolbar {
        ion-buttons {
            &.md {
                @apply md:py-1.5 lg:py-2;
            }
        }

        ion-title {
            &.md {
                @apply md:pb-1.5 md:pt-[0.5rem] lg:pb-2 lg:pt-[0.6rem];
            }
        }
    }

    ion-badge {
        @apply text-[0.813rem] landscape:md:leading-none;
    }

    .ion-bg-orange {
        --background: theme('colors.orange.DEFAULT');
        --color: theme('colors.white.DEFAULT');
    }

    .ion-bg-black {
        background: theme('colors.black.DEFAULT');
        --background: theme('colors.black.DEFAULT');
        --color: theme('colors.white.DEFAULT');
    }

    .ion-bg-black-header {
        --background: theme('colors.black.header');
        --color: theme('colors.white.DEFAULT');
    }

    .ion-bg-gray {
        --background: #2c2c2c;
        --color: theme('colors.white.DEFAULT');
    }

    .ion-bg-white {
        background: theme('colors.white.DEFAULT');
        --background: theme('colors.white.DEFAULT');
        --color: theme('colors.black.DEFAULT');
    }

    .ion-white-datetime-picker {
        --background: rgb(255, 255, 255);
        --background-rgb: 255, 255, 255;
        --wheel-highlight-background: rgb(255, 255, 255);
        --wheel-fade-background-rgb: 255, 255, 255;
    }

    .ion-black-datetime-picker {
        --background: rgb(23, 23, 23);
        --background-rgb: 23, 23, 23;
        --wheel-highlight-background: rgb(23, 23, 23);
        --wheel-fade-background-rgb: 23, 23, 23;
    }

    .ion-bg-transparent-white {
        --background: transparent;
        --color: theme('colors.white.DEFAULT');
    }

    .ion-text-orange {
        --color: theme('colors.orange.DEFAULT');
        color: theme('colors.orange.DEFAULT');
    }

    .ion-text-orange-accent {
        --color: theme('colors.orange.accent');
        color: theme('colors.orange.accent');
    }

    .ion-text-yellow {
        --color: theme('colors.yellow.icon-alert');
        color: theme('colors.yellow.icon-alert');
    }

    .ion-text-white {
        --color: theme('colors.white.DEFAULT');
        color: theme('colors.white.DEFAULT');
    }

    .ion-text-black {
        --color: theme('colors.black.header');
        color: theme('colors.black.header');
    }

    .ion-text-neutral-300 {
        --color: theme('colors.neutral.300');
        color: theme('colors.neutral.300');
    }

    .ion-text-neutral-400 {
        --color: theme('colors.neutral.400');
        color: theme('colors.neutral.400');
    }

    .ion-bg-yellow {
        --background: theme('colors.yellow.icon-alert');
        background: theme('colors.yellow.icon-alert');
    }

    .bg-gray-pattern {
        background: url('./assets/images/backgrounds/bg-grey.jpg') no-repeat center center / cover;
        --background: url('./assets/images/backgrounds/bg-grey.jpg') no-repeat center center / cover;
    }

    .bg-white-pattern {
        background: url('./assets/images/backgrounds/bg-white.jpg') no-repeat center center / cover;
        --background: url('./assets/images/backgrounds/bg-white.jpg') no-repeat center center / cover;
    }

    .mt-safe {
        margin-top: env(safe-area-inset-top);
    }

    app-edit-profile-photo-popover {
        @apply bg-white;
    }

    .speech-bubble-container {
        position: absolute;
        bottom: 52px;
        z-index: 120;
        left: 0.5rem;
        width: 100%;
        height: 100%;
        text-transform: none;
        display: flex;
        flex-direction: column-reverse;

        .bubble {
            font-weight: theme('fontWeight.semibold');
            font-size: theme('fontSize.sm');
            padding: 4px 8px;
            white-space: initial;
            width: max-content;
            max-width: 220px;
            margin: 0 auto 12px;
            border-radius: 2px;
            background: theme('colors.white.DEFAULT');
            position: relative;
            display: flex;
            align-items: center;
            float: right;
            color: theme('colors.black.DEFAULT');

            &:after {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                border: 8px solid transparent;
                border-top-color: theme('colors.white.DEFAULT');
                border-bottom: 0;
                left: 15px;
                bottom: -8px;
            }
        }
    }

    .menu-list {
        @apply flex flex-col space-y-1 px-2;

        .menu-list-item {
            @apply px-6 grid gap-2 grid-cols-6 items-center py-0.5 h-[3.125rem] bg-neutral-100 dark:bg-neutral-950 bg-opacity-30 dark:bg-opacity-100 cursor-pointer hover:bg-opacity-60 hover:bg-white dark:hover:bg-opacity-5 dark:hover:bg-white duration-200 rounded-lg;

            &.mobile {
                @apply lg:px-4;
            }

            .icon-container {
                @apply col-span-1 relative flex;
                .icon {
                    @apply text-black dark:text-white h-6 w-6 md:h-5 md:w-5;
                    --color: theme(colors.white.DEFAULT);
                }
                .new-icon {
                    @apply text-black dark:text-white;
                }
            }
            .label {
                @apply col-span-5 flex items-center justify-between;
                .label-text {
                    @apply text-[16px] text-black dark:text-white font-semibold leading-none;

                    &.mobile {
                        @apply md:text-[18px] lg:text-[20px];
                    }
                }
                .label-badge {
                    @apply ion-bg-yellow ion-text-black dark:ion-text-black;
                }
            }
        }
    }

    .bg-panel {
        @apply bg-white dark:bg-neutral-800;
    }
}

@import 'dark-mode';
@import 'date-picker';

.ngx-ic-source-image {
    margin: auto !important;
}

.flex-remain {
    flex: 0 0 100%;
}

.trbl-0 {
    @apply bottom-0 left-0 right-0 top-0;
}

.inner-border-b-2-orange {
    @apply relative;
}

.inner-border-b-2-orange::after {
    @apply shadow-orange;
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: var(--tw-shadow-color);
}

.player-container-normal-scores {
    left: 50%;
    top: 22%;
    transform: translateX(-50%);
}

.player-container-virt-scores {
    right: 0;
    top: 22%;
    transform: translateX(50%);
}

swiper-container {
    --swiper-pagination-color: #cdcdcd !important;
    --swiper-pagination-bullet-size: 6px !important;
    --swiper-pagination-bullet-width: 6px !important;
    --swiper-pagination-bullet-height: 6px !important;
    --swiper-pagination-bullet-inactive-color: #7e7e7e !important;
    --swiper-pagination-bullet-inactive-opacity: 0.5 !important;
    --swiper-pagination-bullet-opacity: 1 !important;
    --swiper-pagination-bullet-horizontal-gap: 4px !important;
    --swiper-pagination-bullet-vertical-gap: 6px !important;

    &.orange {
        --swiper-pagination-color: #e44e32 !important;
    }
}

.list-shadow {
    box-shadow: 0px 10px 12px 0px rgba(0, 0, 0, 0.3);
}

.global-lobby-shadow {
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
}

video.ingame-camera {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);

    clip-path: inset(1px);
}

.right-page-header {
    @apply flex items-center space-x-1.5;
}

.bg-game-card-top {
    @apply bg-neutral-50 dark:bg-black;
}

.bg-game-card-bottom {
    @apply bg-white dark:bg-neutral-900;
}

.new-game-card {
    @apply space-y-4 rounded-md bg-panel p-4;

    &.tournament {
        @apply bg-neutral-50 dark:bg-neutral-800;

        .title-container {
            .icon {
                @apply bg-white dark:bg-neutral-950;
            }
        }
    }

    &.light-dark {
        @apply bg-white dark:bg-black;

        .title-container {
            .icon {
                @apply bg-neutral-50 dark:bg-neutral-900;
            }
        }
    }

    .title-container {
        @apply flex items-center;

        .icon {
            @apply inline-flex h-6.5 w-6.5 items-center justify-center rounded-full bg-neutral-50 text-black;
        }
        .title {
            @apply ml-1.5 font-bebas-neue text-xl text-black;
        }
    }
}

.statistics-card {
    @apply rounded-md bg-white text-black dark:bg-black p-4;

    .title-container {
        @apply flex items-center mb-2;

        .icon {
            @apply inline-flex h-6.5 w-6.5 items-center justify-center rounded-full bg-neutral-50 dark:bg-neutral-900;
        }
        .title {
            @apply ml-1.5 font-bebas-neue text-xl text-black;
        }
    }

    .stats-list-container {
        @apply space-y-1;
    }
}

.list-switch {
    @apply flex w-full items-center justify-between divide-x-2 divide-neutral-50 dark:divide-neutral-700 rounded-sm border-2 border-neutral-50 dark:border-neutral-700;

    &.darker {
        @apply divide-neutral-50 dark:divide-neutral-800 border-neutral-50 dark:border-neutral-800;
    }

    .switch-item {
        border: inherit;
        border-width: 0;
        @apply w-full whitespace-nowrap bg-transparent px-1 py-2.5 text-center  text-xs font-bold uppercase text-black dark:text-white cursor-pointer;

        &.darker {
            @apply bg-white dark:bg-neutral-900;
        }

        &.orange {
            @apply bg-orange text-white dark:text-white;
        }

        &.selected {
            @apply bg-neutral-900 dark:bg-black text-white dark:text-white;
        }

        &.medium {
            @apply text-sm;
        }
    }
}

.new-game-player-container {
    @apply relative m-0 w-fit;

    .circle {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: -4px;
        border-radius: 20px;
        width: 1.15rem;
        bottom: 0;
        height: 1.15rem;
        font-weight: bold;
        text-align: center;
        @apply bg-black;
        &.high {
            top: 0 !important;
            right: -6px !important;
        }
        &.danger {
            @apply bg-red dark:bg-red;
        }
        &.orange {
            @apply bg-orange dark:bg-orange;
        }
        &.primary {
            @apply bg-blue dark:bg-blue;
        }
        &.gray {
            @apply bg-gray-300;
        }
    }
}

.slide-modal-outer {
    @apply flex relative bottom-0 w-full flex-col overflow-y-hidden overscroll-none rounded-t-lg bg-white text-black;

    &.slide-modal-full {
        @apply h-full rounded-none;
    }

    &:not(.slide-modal-full) {
        @apply mt-safe;
    }

    &.bg-pattern {
        @apply bg-white-pattern dark:bg-gray-pattern;
    }

    .slide-modal-header {
        @apply relative flex items-center rounded-t-lg py-5 px-safe-offset-4 min-h-[3.75rem];

        .slide-modal-close {
            @apply ml-1 text-center text-sm font-bold text-black cursor-pointer relative z-50;
        }

        .slide-modal-title {
            @apply absolute left-0 right-0 my-auto w-full text-center font-bebas-neue text-2xl text-black;
        }
    }

    .slide-modal-content {
        @apply flex flex-1 flex-col text-black px-4 overflow-y-auto pb-safe-offset-4;
    }
}

.dialog-input {
    @apply w-full rounded-md border bg-white dark:bg-neutral-950 border-neutral-100 dark:border-neutral-700 px-4 py-3 text-black placeholder:text-neutral-700 dark:placeholder:text-neutral-300 focus:border-orange dark:focus:border-orange;

    &.with-icon {
        @apply flex items-center space-x-2 focus-within:border-orange dark:focus-within:border-orange;

        input {
            @apply bg-transparent w-full placeholder:text-neutral-700 dark:placeholder:text-neutral-300;
        }

        app-icon {
            @apply text-neutral-300;
        }
    }
}

.dialog-input-label {
    @apply text-xs font-bold uppercase text-neutral-900 mb-1;
}

.checkmark {
    @apply absolute top-0 left-0 rounded-sm border-black dark:border-white bg-white dark:bg-neutral-800 border;
}

.external-cam-input-wrapper {
    font-family: 'Bebas Neue';
    @apply flex justify-center items-center space-x-2;

    .external-cam-input {
        width: 50px;
        background-color: #ccc;
        float: left;
        border-radius: 5px;
        padding-bottom: 7px;
        line-height: 40px;
        font-size: 44px;
        input {
            caret-color: transparent;
            background: none;
            width: 100%;
            text-align: center;
            &::placeholder {
                color: #b6b4b4;
            }
            color: #444;
        }
        input[type='number']::-webkit-outer-spin-button,
        input[type='number']::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        input[type='number'] {
            -moz-appearance: textfield;
        }
        .bottom-line {
            width: 75%;
            height: 4px;
            background: #b6b4b4;
            margin: 0 auto;
            &.filled {
                @apply bg-black dark:bg-black;
            }
            &.selected {
                background-color: theme('colors.orange.DEFAULT') !important;
            }
        }
    }
}

.infinite-loading {
    .infinite-loading-spinner {
        color: white;
        --color: white;
    }

    .infinite-loading-text {
        color: white;
        --color: white;
    }
}

.normal-keyboard-container {
    @apply flex h-full flex-col divide-y divide-white dark:divide-black border-white font-bebas-neue text-3xl text-white portrait:xs:text-2xl portrait:sm:text-4xl landscape:sm:text-3xl portrait:lg:text-5xl landscape:lg:text-4xl portrait:border-b;
    .key-wrapper {
        @apply flex flex-1;
        > * + * {
            @apply border-solid border-l border-white dark:border-black;
        }
        .icon-key {
            @apply flex flex-1 items-center justify-center text-center cursor-pointer;
        }
        .key {
            @apply grid flex-1 items-center text-center cursor-pointer text-black dark:text-white;
        }
    }
}

.in-game-stats-container {
    @apply w-full h-full pb-5 pt-3 landscape:pt-0 landscape:lg:pt-4;
    &.expanded {
        @apply space-y-4 landscape:space-y-2;
    }
    &.wrapped {
        @apply flex flex-wrap items-center justify-center pb-4 pt-2 gap-4;
    }
    .in-game-stat {
        @apply flex flex-col h-fit items-center justify-start text-center portrait:space-y-1.5 landscape:space-y-0.5 landscape:md:space-y-1.5;
        &.small {
            @apply portrait:space-y-1 landscape:space-y-0.5 landscape:md:space-y-1;
        }
        .stat {
            @apply font-bebas-neue text-5xl sm:text-6xl font-normal leading-none text-black;
            &.small {
                @apply text-[2.25rem] sm:text-[3rem] leading-none;
            }
            &.big {
                @apply text-6xl sm:text-7xl leading-none;
            }
            &.xl {
                @apply text-7xl sm:text-8xl leading-none;
            }
        }
        .stat-name {
            @apply text-sm sm:text-base font-semibold uppercase text-orange leading-none whitespace-nowrap;
            &.small {
                @apply text-xs sm:text-sm leading-none;
            }
            &.big {
                @apply text-base sm:text-lg leading-none;
            }
            &.xl {
                @apply text-lg sm:text-xl leading-none;
            }
        }
    }
}

.selectedMultiplier {
    box-shadow: #e44e32 0px -3px 0px inset;
}

.player-container-stats {
    @apply relative inline-flex w-full flex-1 flex-col items-center justify-start px-3 landscape:pt-2;
}

.player-container-user-details {
    @apply inline-flex w-full flex-col items-center justify-start px-4 pt-4;

    .details-wrapper {
        @apply flex w-full flex-col items-center justify-center space-y-2 border-b-2 border-neutral-100 pb-4;

        &.current-turn {
            @apply border-orange dark:border-orange border-opacity-75;
        }
    }

    .current-player-wrapper {
        @apply flex w-full flex-col items-center justify-center border-b-2 border-neutral-100 pb-4;
    }
}

.started-leg-indicator {
    @apply relative inline-flex h-2.5 w-2.5 rounded-full bg-orange flex-none;
}

.in-game-score-field {
    @apply h-9 w-full bg-neutral-50 dark:bg-neutral-50 pt-1 font-bebas-neue text-2xl text-black dark:text-black;
}

.cricket-turn-score-field {
    @apply h-9 w-full bg-neutral-50 pt-1 font-bebas-neue text-2xl text-black;
}

.submit-score-button {
    @apply flex flex-col items-center justify-center space-y-0.5 rounded-tr-xl bg-green px-4 py-1 text-center font-bebas-neue leading-none text-white dark:text-white cursor-pointer;
}

.allthrown-button {
    @apply flex items-center justify-center space-y-0.5 bg-green px-4 py-1 text-center font-bebas-neue leading-none text-white dark:text-white cursor-pointer;
}

.small-player-container-stat {
    @apply text-xl sm:text-2xl font-semibold uppercase leading-none text-black;

    .virt {
        @apply sm:text-xl;
    }
}

.option-button-wrapper {
    @apply inline-flex h-7 w-7 min-h-7 min-w-7 items-center justify-center rounded-full bg-neutral-200 dark:bg-white bg-opacity-30 dark:bg-opacity-30 p-1 cursor-pointer;
}

ion-popover::part(content) {
    background: transparent;
}

ion-popover::part(arrow) {
    &::after {
        @apply bg-neutral-50 dark:bg-neutral-900;
    }
}

.popover-container {
    @apply bg-neutral-50 dark:bg-neutral-900 rounded-md divide-y divide-neutral-100 dark:divide-neutral-700 flex flex-col shadow-md;

    .popover-button {
        @apply h-[3rem] text-black uppercase text-center cursor-pointer grid items-center leading-none;
    }
}

.audio-meter {
    display: flex;
    align-items: center;
    justify-content: space-between; // This will place children at both ends of the container
    position: relative; // Positioning context for absolutely positioned children
    width: 100%; // Adjust as needed

    .mute-button {
        // Ensure the buttons don't grow with the flex container
        flex-shrink: 0;
    }

    .volume-bar-container {
        // This container will hold the volume bar and allow it to grow
        flex-grow: 1;
        position: relative; // Relative positioning for the volume bar

        .volume-bar {
            height: 10px;
            border-radius: 5px;
            transition: width 0.2s;
            position: absolute;
            top: 0;
            left: 0;
            width: 0%; // Initial width
            &.muted {
                background-color: #aaa; // A gray color to indicate muted status
            }
        }
    }
}

.ucfirst:first-letter {
    text-transform: uppercase;
}

.game-stat-main-table-container {
    @apply grid grid-cols-9 gap-1 text-black text-sm items-center;
}

.game-stat-table-container {
    @apply relative grid grid-cols-9 gap-1 py-1 text-black text-sm items-center;
}

.game-specific-detail-stat {
    @apply col-span-2 rounded-md bg-orange py-1 text-center text-sm h-fit min-h-7 cursor-pointer text-white dark:text-white;
}

.auto-height-dialog {
    @apply relative rounded-lg bg-white text-black px-6 py-10 max-h-dvh;

    .close-button {
        @apply absolute right-4 top-4 w-[1.5rem] cursor-pointer;
    }
    .title-container {
        @apply flex flex-col items-center space-y-2.5;

        .title {
            @apply text-center text-xl font-bold text-black;
        }
        .subtitle {
            @apply text-center text-sm text-neutral-400;
        }
    }
    .button-container {
        @apply mt-8 w-full;
    }
}

.score-input-container {
    @apply flex items-stretch space-x-2 rounded-t-3xl bg-white dark:bg-white p-3;
}

// SVG classes for the OMNI coordinate views
.zoomed-container {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    background: #171717;
    border-radius: 9px 9px 0 0;
}

.zoomed-container svg {
    @apply h-full w-full top-0 left-0 absolute;
}

.omni-dartboard-container {
    @apply min-h-[20rem];
    svg {
        @apply h-full w-full;
    }
}

.omni-heatmap-container {
    @apply min-h-[20rem];
    svg {
        @apply h-full w-full;
    }
}

.omni-placeholder {
    transform: translate(-50%, -50%);
}

.zoom-transition {
    transition: all 0.5s ease-in-out; /* Adjust timing and easing as needed */
}

.zoomed-svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

ion-modal {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.4) !important;
}

.dark ion-modal {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.7) !important;
}

/* Chrome, Safari, Edge, Opera */
.hide-number-input-arrows::-webkit-outer-spin-button,
.hide-number-input-arrows::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.hide-number-input-arrows[type='number'] {
    -moz-appearance: textfield;
}

.grecaptcha-badge {
    visibility: hidden !important;
}

.end-screen-slide {
    @apply flex flex-col divide-y divide-white  dark:divide-black dark:border-black;

    .stat-container {
        @apply grid grid-cols-3 items-center py-2 px-4;

        &.three {
            @apply grid-cols-4;
        }

        &.four {
            @apply grid-cols-5;
        }

        .stat-score {
            @apply col-span-1 font-bebas-neue text-black text-[1.5rem] leading-none text-center whitespace-normal flex items-center space-x-1 justify-center;

            &.small {
                @apply text-[1.3rem];
            }
        }

        .stat-name-container {
            @apply col-span-1  text-black flex items-center justify-center;

            .stat-name {
                @apply font-semibold uppercase text-[0.8rem] leading-none whitespace-nowrap bg-neutral-700 dark:bg-neutral-100 text-white  py-1 px-1.5 rounded;

                &.small {
                    @apply text-[0.6rem];
                }
            }
        }
    }
}

.local-end-screen-player-container {
    @apply grid grid-cols-3 items-center w-full px-4 overflow-hidden;

    &.has-absolute {
        @apply absolute left-0 right-0;
    }

    &.three {
        @apply grid-cols-4;
    }

    &.four {
        @apply grid-cols-5;
    }

    .player {
        @apply col-span-1 flex flex-col items-center overflow-hidden;
    }
}

.bottom-nav-container {
    @apply flex items-center justify-evenly bg-white px-1 font-open-sans pb-safe;

    .nav-option {
        @apply flex w-16 h-full flex-col items-center justify-center space-y-0.5 py-1.5 text-neutral-200 dark:text-neutral-300 cursor-pointer overflow-hidden;

        &.icon-only {
            @apply py-3;
        }

        .nav-text {
            @apply text-center text-xs truncate w-full;
        }
    }
}

.tab-toggle-container {
    @apply flex h-16 min-h-16 flex-none bg-white text-black text-xl font-bebas-neue landscape:px-safe;
}

.tab-toggle {
    @apply inline-flex basis-1/2 items-center justify-center cursor-pointer;
}

.ion-page > #main-content {
    margin-top: 0; /* Default margin */
    transition: margin-top 0.5s ease-in-out;
}

.push-content .ion-page > #main-content {
    margin-top: 4.6rem;
}

.global-lobby-filter {
    @apply flex flex-col justify-center items-center rounded-md w-[5.75rem] h-[5.75rem] flex-none cursor-pointer space-y-3 border-2 border-neutral-50 dark:border-neutral-900;

    &.not-active {
        @apply bg-white dark:bg-black;
    }

    &.active {
        @apply bg-neutral-50 dark:bg-neutral-900;
    }

    .top {
        @apply flex flex-col justify-end items-center basis-1/2;
    }

    .bottom {
        @apply flex flex-col justify-start items-center basis-1/2;
    }
}

.end-screen-badge {
    @apply rounded-lg px-1.5 py-1 border-white dark:border-white text-white dark:text-white border text-xs font-bold;
}

.global-lobby-filter-badge {
    @apply rounded-full px-2.5 bg-white text-black text-xxxs leading-none uppercase font-bold flex items-center space-x-1 h-7 flex-none;
}

.help-tooltip-icon {
    @apply bg-blue p-0.5 text-white dark:text-white rounded-full cursor-pointer ml-2;
}

.onboarding-dialog {
    @apply flex h-full flex-col overflow-y-auto overscroll-none bg-white-pattern;

    .onboarding-container {
        @apply mt-2 flex-1 rounded-t-4xl bg-white pt-6 text-black px-safe flex flex-col overflow-hidden;

        .onboarding-tabs {
            @apply flex-none space-y-3 bg-white px-5;

            .tabs-container {
                @apply flex items-center justify-between space-x-2;

                .tab {
                    @apply h-1.5 w-full rounded-full;
                }
            }
        }
        .slide-container {
            @apply mt-8 flex flex-col bg-white flex-1 overflow-x-hidden overflow-y-auto;

            .onboarding-slide {
                @apply flex-remain space-y-4 px-5 flex flex-col justify-center items-center text-lg md:text-xl text-black;

                .icon {
                    @apply flex-none inline-flex h-16 w-16 items-center justify-center rounded-full bg-orange;
                }

                .title {
                    @apply text-center text-2xl md:text-3xl font-bold;
                }

                .body {
                    @apply text-center;
                }
            }
        }
    }

    .onboarding-buttons {
        @apply flex space-x-2 bg-white px-5 pt-5 pb-safe-offset-5 flex-none;
    }
}

.favourite_double {
    .selected {
        background-color: theme('colors.orange.DEFAULT') !important;
    }
}

#qr-code-reader > video {
    @apply rounded-md;
}

ion-range::part(tick) {
    @apply bg-orange bg-opacity-20;
}

ion-range::part(tick-active) {
    @apply bg-orange;
}

ion-range::part(pin) {
    @apply bg-orange;
}

ion-range::part(knob) {
    @apply bg-orange;
}

ion-range::part(bar) {
    @apply bg-orange bg-opacity-20;
}

ion-range::part(bar-active) {
    @apply bg-orange;
}

.hidden {
    display: none !important;
}
