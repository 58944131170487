@font-face {
    font-family: 'Dartcounter';
    src: url('fonts/Dartcounter.eot?v4nksj');
    src:
        url('fonts/Dartcounter.eot?v4nksj#iefix') format('embedded-opentype'),
        url('fonts/Dartcounter.ttf?v4nksj') format('truetype'),
        url('fonts/Dartcounter.woff?v4nksj') format('woff'),
        url('fonts/Dartcounter.svg?v4nksj#Dartcounter') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'Dartcounter' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-qr_code:before {
    content: '\e90d';
}
.icon-virtcam:before {
    content: '\e90b';
}
.icon-volume_high:before {
    content: '\e949';
}
.icon-volume_low:before {
    content: '\e9c3';
}
.icon-volume_medium:before {
    content: '\e9c4';
}
.icon-volume_mute:before {
    content: '\e9c5';
}
.icon-volume_off:before {
    content: '\e9c6';
}
.icon-push_pin:before {
    content: '\e9c1';
}
.icon-push_pin_outline:before {
    content: '\e9c0';
}
.icon-questionmark:before {
    content: '\e9c2';
}
.icon-activity_log:before {
    content: '\e9bf';
}
.icon-notifications:before {
    content: '\e9be';
}
.icon-info_circle:before {
    content: '\e9bd';
}
.icon-closed_lock:before {
    content: '\e9bb';
}
.icon-open_lock:before {
    content: '\e9bc';
}
.icon-tournaments:before {
    content: '\e9ba';
}
.icon-search:before {
    content: '\e9b7';
}
.icon-calendar:before {
    content: '\e9b6';
}
.icon-bracket:before {
    content: '\e91b';
}
.icon-lock:before {
    content: '\e915';
}
.icon-mic_off:before {
    content: '\e9b3';
}
.icon-voice_call:before {
    content: '\e9b4';
}
.icon-camera_reverse_outline:before {
    content: '\e9b5';
}
.icon-action_replays:before {
    content: '\e9b2';
}
.icon-bin:before {
    content: '\e9b0';
}
.icon-dart:before {
    content: '\e9b1';
}
.icon-undo_outline:before {
    content: '\e9af';
}
.icon-undo:before {
    content: '\e9ae';
}
.icon-keyboard_only:before {
    content: '\e9b9';
}
.icon-keyboard_shortcuts:before {
    content: '\e977';
}
.icon-keyboard_single:before {
    content: '\e9ad';
}
.icon-keyboard_default:before {
    content: '\e9b8';
}
.icon-tactics_two:before {
    content: '\e9a7';
}
.icon-tactics_one:before {
    content: '\e9a8';
}
.icon-tactics_full:before {
    content: '\e9a9';
}
.icon-speedmode:before {
    content: '\e9aa';
}
.icon-ingame_timer:before {
    content: '\e9ab';
}
.icon-dartboard:before {
    content: '\e9ac';
}
.icon-bookmark_filled_star_outline:before {
    content: '\e9a4';
}
.icon-bookmark_star_outline:before {
    content: '\e9a5';
}
.icon-hangup:before {
    content: '\e9a6';
}
.icon-bookmark_outline:before {
    content: '\e9a2';
}
.icon-bookmark:before {
    content: '\e9a3';
}
.icon-check_mark:before {
    content: '\e9a1';
}
.icon-loading:before {
    content: '\e9a0';
}
.icon-three_dots:before {
    content: '\e99f';
}
.icon-gift:before {
    content: '\e99c';
}
.icon-gift_outline:before {
    content: '\e99d';
}
.icon-logout:before {
    content: '\e99e';
}
.icon-login:before {
    content: '\e99a';
}
.icon-in_game_comms:before {
    content: '\e99b';
}
.icon-access_alarms:before {
    content: '\e900';
}
.icon-account_box:before {
    content: '\e901';
}
.icon-account_circle:before {
    content: '\e902';
}
.icon-add:before {
    content: '\e903';
}
.icon-add_box:before {
    content: '\e904';
}
.icon-add_circle:before {
    content: '\e905';
}
.icon-add_circle_outline:before {
    content: '\e906';
}
.icon-add_comment:before {
    content: '\e907';
}
.icon-addchart:before {
    content: '\e908';
}
.icon-apps:before {
    content: '\e909';
}
.icon-arrow_back:before {
    content: '\e90a';
}
.icon-arrow_forward:before {
    content: '\e90c';
}
.icon-assessment:before {
    content: '\e90e';
}
.icon-autorenew:before {
    content: '\e90f';
}
.icon-backspace:before {
    content: '\e910';
}
.icon-cached:before {
    content: '\e911';
}
.icon-camera_alt:before {
    content: '\e912';
}
.icon-chat_bubble:before {
    content: '\e913';
}
.icon-check_circle:before {
    content: '\e914';
}
.icon-close:before {
    content: '\e916';
}
.icon-close_fullscreen:before {
    content: '\e917';
}
.icon-compare_arrows:before {
    content: '\e918';
}
.icon-crown:before {
    content: '\e919';
}
.icon-dashboard:before {
    content: '\e91a';
}
.icon-delete_forever:before {
    content: '\e91c';
}
.icon-design_services:before {
    content: '\e91d';
}
.icon-disabled_by_default:before {
    content: '\e91e';
}
.icon-done:before {
    content: '\e91f';
}
.icon-edit:before {
    content: '\e920';
}
.icon-emoji_events:before {
    content: '\e921';
}
.icon-error:before {
    content: '\e922';
}
.icon-file_copy:before {
    content: '\e923';
}
.icon-flip_camera_ios:before {
    content: '\e924';
}
.icon-format_quote:before {
    content: '\e925';
}
.icon-fullscreen:before {
    content: '\e926';
}
.icon-fullscreen_exit:before {
    content: '\e927';
}
.icon-gps_fixed:before {
    content: '\e928';
}
.icon-help:before {
    content: '\e929';
}
.icon-help_center:before {
    content: '\e92a';
}
.icon-home:before {
    content: '\e92c';
}
.icon-home_work:before {
    content: '\e92d';
}
.icon-import_export:before {
    content: '\e92e';
}
.icon-insert_emoticon:before {
    content: '\e92f';
}
.icon-insights:before {
    content: '\e930';
}
.icon-keyboard_arrow_down:before {
    content: '\e931';
}
.icon-keyboard_arrow_left:before {
    content: '\e932';
}
.icon-keyboard_arrow_left-reduced:before {
    content: '\e933';
}
.icon-keyboard_arrow_right:before {
    content: '\e934';
}
.icon-keyboard_arrow_up:before {
    content: '\e935';
}
.icon-keyboard_hide:before {
    content: '\e936';
}
.icon-language:before {
    content: '\e937';
}
.icon-launch:before {
    content: '\e938';
}
.icon-leak_add:before {
    content: '\e939';
}
.icon-leak_remove:before {
    content: '\e93a';
}
.icon-linked_camera:before {
    content: '\e93b';
}
.icon-location_on:before {
    content: '\e93c';
}
.icon-location_searching:before {
    content: '\e93d';
}
.icon-loop:before {
    content: '\e93e';
}
.icon-mail:before {
    content: '\e93f';
}
.icon-maps_ugc:before {
    content: '\e940';
}
.icon-mediation:before {
    content: '\e941';
}
.icon-menu:before {
    content: '\e942';
}
.icon-menu_open:before {
    content: '\e943';
}
.icon-mic:before {
    content: '\e944';
}
.icon-more_horiz:before {
    content: '\e945';
}
.icon-more_vert:before {
    content: '\e946';
}
.icon-my_location:before {
    content: '\e947';
}
.icon-new_releases:before {
    content: '\e948';
}
.icon-open_in_full:before {
    content: '\e94a';
}
.icon-open_in_new:before {
    content: '\e94b';
}
.icon-pan_tool:before {
    content: '\e94c';
}
.icon-pause:before {
    content: '\e94e';
}
.icon-pause_circle_filled:before {
    content: '\e94f';
}
.icon-pause_circle_outline:before {
    content: '\e950';
}
.icon-pending:before {
    content: '\e951';
}
.icon-people_alt:before {
    content: '\e952';
}
.icon-person:before {
    content: '\e953';
}
.icon-person_add:before {
    content: '\e955';
}
.icon-person_remove:before {
    content: '\e956';
}
.icon-person_search:before {
    content: '\e958';
}
.icon-photo_camera:before {
    content: '\e959';
}
.icon-picture_in_picture:before {
    content: '\e95a';
}
.icon-picture_in_picture_alt:before {
    content: '\e95b';
}
.icon-play_arrow:before {
    content: '\e95c';
}
.icon-play_circle_filled:before {
    content: '\e95d';
}
.icon-play_circle_outline:before {
    content: '\e95e';
}
.icon-portable_wifi_off:before {
    content: '\e95f';
}
.icon-power_settings_new:before {
    content: '\e960';
}
.icon-public:before {
    content: '\e962';
}
.icon-question_answer:before {
    content: '\e963';
}
.icon-recent_actors:before {
    content: '\e964';
}
.icon-remove:before {
    content: '\e965';
}
.icon-remove_circle:before {
    content: '\e966';
}
.icon-remove_circle_outline:before {
    content: '\e967';
}
.icon-repeat:before {
    content: '\e968';
}
.icon-replay:before {
    content: '\e969';
}
.icon-report:before {
    content: '\e96a';
}
.icon-report_problem:before {
    content: '\e96b';
}
.icon-rotate_90_degrees_ccw:before {
    content: '\e96c';
}
.icon-rotate_left:before {
    content: '\e96d';
}
.icon-rotate_right:before {
    content: '\e96e';
}
.icon-router:before {
    content: '\e96f';
}
.icon-rss_feed:before {
    content: '\e970';
}
.icon-bookmark_star:before {
    content: '\e971';
}
.icon-screen_lock_landscape:before {
    content: '\e972';
}
.icon-screen_lock_portrait:before {
    content: '\e973';
}
.icon-screen_lock_rotation:before {
    content: '\e974';
}
.icon-screen_rotation:before {
    content: '\e975';
}
.icon-send:before {
    content: '\e976';
}
.icon-settings:before {
    content: '\e978';
}
.icon-settings_remote:before {
    content: '\e979';
}
.icon-share:before {
    content: '\e97a';
}
.icon-show_chart:before {
    content: '\e97b';
}
.icon-speaker_phone:before {
    content: '\e97c';
}
.icon-star:before {
    content: '\e97d';
}
.icon-stop:before {
    content: '\e97e';
}
.icon-stop_circle:before {
    content: '\e97f';
}
.icon-supervisor_account:before {
    content: '\e980';
}
.icon-switch_video:before {
    content: '\e981';
}
.icon-sync:before {
    content: '\e982';
}
.icon-sync_problem:before {
    content: '\e983';
}
.icon-timeline:before {
    content: '\e984';
}
.icon-timer:before {
    content: '\e985';
}
.icon-timer_3:before {
    content: '\e986';
}
.icon-timer_10:before {
    content: '\e987';
}
.icon-touch_app:before {
    content: '\e988';
}
.icon-trending_up:before {
    content: '\e989';
}
.icon-tune:before {
    content: '\e98a';
}
.icon-verified:before {
    content: '\e98b';
}
.icon-verified_user:before {
    content: '\e98c';
}
.icon-video_call:before {
    content: '\e98d';
}
.icon-video_settings:before {
    content: '\e98e';
}
.icon-videocam:before {
    content: '\e98f';
}
.icon-videocam_off:before {
    content: '\e990';
}
.icon-view_agenda:before {
    content: '\e991';
}
.icon-visibility:before {
    content: '\e992';
}
.icon-visibility_off:before {
    content: '\e993';
}
.icon-warning:before {
    content: '\e994';
}
.icon-watch_later:before {
    content: '\e995';
}
.icon-wifi_calling:before {
    content: '\e996';
}
.icon-wifi_off:before {
    content: '\e997';
}
.icon-wifi_protected_setup:before {
    content: '\e998';
}
.icon-zoom_out_map:before {
    content: '\e999';
}
