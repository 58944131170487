/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */

ion-list {
    margin: 0 !important;
}

.ion-fullscreen-modal {
    @media only screen and (min-width: 768px) and (min-height: 600px) {
        --width: 100% !important;
        --height: 100% !important;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
    @media only screen and (min-width: 768px) and (min-height: 768px) {
        --width: 100% !important;
        --height: 100% !important;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
}

swiper-container.dc-swiper {
    --swiper-pagination-bullet-inactive-color: var(--ion-text-color-step-800, #cccccc);
    --swiper-pagination-color: var(--ion-color-primary, #3880ff);
    --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
    --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
    --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);

    swiper-slide {
        // --swiper-pagination-color: var(--swiper-theme-color);
        // --swiper-pagination-bullet-size: 8px;
        // --swiper-pagination-bullet-width: 8px;
        // --swiper-pagination-bullet-height: 8px;
        // --swiper-pagination-bullet-inactive-color: #000;
        // --swiper-pagination-bullet-inactive-opacity: 0.2;
        // --swiper-pagination-bullet-opacity: 1;
        // --swiper-pagination-bullet-horizontal-gap: 4px;
        // --swiper-pagination-bullet-vertical-gap: 6px;

        display: flex;
        position: relative;

        flex-direction: column;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;

        font-size: 18px;

        text-align: center;
        box-sizing: border-box;
    }

    swiper-slide img {
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 100%;
    }
}

.dc-swiper {
    --add-bottom: 33px;
    padding-bottom: var(--add-bottom);

    [class^="swiper-button-"] {
        top: calc(50% - var(--add-bottom) / 2);
    }
}

.leftrotate {
    transform: rotate(-90deg);
    bottom: 30px !important;
    right: 0 !important;
}

.rightrotate {
    transform: rotate(90deg);
    bottom: 30px !important;
    right: 0 !important;
}

video.flip {
    &.horizontal {
        -webkit-transform: scaleY(-1) !important;
        transform: scaleY(-1) !important;
    }
    &.vertical {
        -webkit-transform: scaleX(-1) !important;
        transform: scaleX(-1) !important;
    }
}

:focus {
    outline: none;
}

ion-footer ion-toolbar:last-of-type {
    padding-bottom: 0 !important;
}

.sc-ion-modal-ios-h {
    --background: none !important;
}

.ribbonButton {
    @apply bg-blue;
    padding: 5px 0;
    color: white;
    font-size: 17px;
    text-transform: uppercase;
    margin-bottom: 5px;
    line-height: 34px;
    ion-icon {
        vertical-align: middle;
        margin-right: 5px;
    }
}

@font-face {
    font-family: "Open Sans";
    src: url("assets/fonts/Open_Sans/OpenSans-VariableFont_wdth,wght.ttf") format("truetype");
    font-weight: 100 900;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    src: url("assets/fonts/Open_Sans/OpenSans-Italic-VariableFont_wdth,wght.ttf") format("truetype");
    font-weight: 100 900;
    font-style: italic;
}

@font-face {
    font-family: "Bebas Neue";
    src: url("assets/fonts/Bebas_Neue/BebasNeue-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */

@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";
@import "animate.css/animate.css";

:root {
    --blue: #1d70b7;
    --blue-highlight: #3880ff;
    --blue-shade: #54738f;
    --orange: #e44e32;
    --red: #eb445a;
    // --black: #444444;
    --grey: #cccccc;
    --black: #2d2d2d;
    --white: #ffffff;
}

.bg_slider {
    background-image: linear-gradient(90deg, #00af5b 0%, #00af5b 50%, #00eb7a 50%, #00eb7a 100%);
    background-size: 200%;
}

.spectating-button {
    font-size: 24px !important;
    background-size: 200%;
    background-color: #242424 !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    color: var(--white);
}

.bg_slider.active {
    transition:
        background-position 1s cubic-bezier(0.47, 0.1, 1, 0.63),
        color 1s linear;
    transition-delay: 0s, 0.15s;
    background-position: -100% 100%;
}

ion-button,
ion-card {
    --border-radius: unset;
    border-radius: 0;
}

ion-toolbar.footer {
    padding: 0 !important;
    --padding-top: 0;
    --padding-inline-start: 0;
    --padding-start: 0;
    --padding-end: 0;
}

ion-card {
    box-shadow: none !important;
}

.tooltip {
    font-size: 13px;
}

.action-sheet-button {
    border-bottom: 1px solid #ccc !important;
    --ripple-color: transparent;
    ion-ripple-effect {
        display: none;
    }
}

ion-toolbar.transparent {
    --background: transparent !important;
    --color: var(--white);
}

.action-sheet-group-cancel {
    .action-sheet-button {
        background: var(--black);
        color: var(--white);
    }
    .action-sheet-button-inner {
        justify-content: center;
        border-bottom: 0;
    }
}

.centerFlex {
    justify-content: center;
    align-items: center;
    display: flex;
}

.centerFlexStart {
    justify-content: center;
    align-items: flex-start;
    display: flex;
}

ion-back-button {
    --color: var(--black);
}

ion-back-button.md .button-text {
    display: none;
}

.card-content-ios {
    padding: 10px;
}

.no-ripple,
ion-button,
button {
    --ripple-color: transparent;
    ion-ripple-effect {
        display: none;
    }
}

.scroll-content {
    padding-bottom: 0 !important;
}

.loading-wrapper {
    --background: transparent;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    --spinner-color: var(--orange);
    display: flex;
    flex-direction: column;
}

.whiteText {
    .loading-wrapper {
        --spinner-color: white !important;
    }
    color: white;
}

.swal2-icon.swal2-success .swal2-success-ring {
    border: 0.25em solid rgba(29, 112, 183, 0.3) !important;
}

.blurred .swal2-container {
    background: rgb(197 197 197 / 40%) !important;
    backdrop-filter: blur(5px);
}

.not-blurred {
    backdrop-filter: none !important;
}

.swal2-popup.swal2-toast {
    box-shadow: none !important;
}

.swal2-backdrop-show {
    height: 100%;
    backdrop-filter: blur(5px);
}

.colored-toast.swal2-icon-error {
    background-color: var(--red) !important;
    color: var(--white);
    .swal2-title {
        color: var(--white);
        padding: 0 5px !important;
    }
    .swal2-content {
        color: var(--white);
        padding: 0 5px !important;
    }
}

.swal2-popup.blurred {
    border-radius: 20px !important;
    .swal2-content {
        &.swal2-icon-success {
            color: var(--blue);
        }
        &.swal2-icon-warning {
            color: var(--black);
        }
        text-transform: uppercase;
        font-size: 20px;
        padding: 0 10px 15px 10px;
    }
    .swal2-icon.swal2-success {
        border-color: var(--blue) !important;
        color: var(--blue-shade) !important;
    }
    .swal2-icon.swal2-success [class^="swal2-success-line"] {
        background-color: var(--blue) !important;
    }
    .swal2-success-circular-line-left,
    .swal2-success-circular-line-right,
    .swal2-success-fix {
        background: transparent !important;
    }
}

// http://ionicframework.com/docs/v2/theming/
// App Global Sass
// --------------------------------------------------
// Put style rules here that you want to apply globally. These
// styles are for the entire app and not just one component.
// Additionally, this file can be also used as an entry point
// to import other Sass files to be included in the output CSS.
//
// Shared Sass variables, which can be used to adjust Ionic's
// default Sass variables, belong in "theme/variables.scss".
//
// To declare rules for a specific mode, create a child rule
// for the .md, .ios, or .wp mode classes. The mode class is
// automatically applied to the <body> element in the app.

body {
    padding-top: constant(safe-area-inset-top); //for iOS 11.2
    padding-top: env(safe-area-inset-top); //for iOS 11.1
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Open sans";
}

ion-action-sheet {
    z-index: 1000000 !important;
}

ion-app,
p,
span,
body {
    font-family: "Open sans" !important;
}

button {
    font-family: "Open sans";
}

.freeMatchesInfo {
    background: #403f3f;
    color: var(--white);
    border-top: 1px solid #4a4949;
    padding: 5px;
}

ion-title {
    font-family: "Bebas Neue";
    font-weight: normal;
    font-size: 23px;
    padding-inline: 5px;
}

.loading-ios .loading-wrapper {
    background: transparent !important;
}

.popover-content {
    width: 320px !important;
}

.statusButton {
    min-width: 90px;
    font-weight: 500;
    font-size: 14px;
    height: 30px;
}

/* NEW CSS 2019 */

.scrollList {
    // max-height: 180px;
    max-height: calc(100% - 115px);
    overflow-y: scroll;
}

.header-logo {
    height: 22px;
    margin-top: 2px;
}

.paddedside {
    padding: 5px 16px;
}

.topCTA {
    top: 0;
    width: 100%;
    z-index: 100;
    position: sticky;
    color: var(--white);
    display: inline-block;
    background: var(--black);
    &.blue {
        background: var(--blue);
        &.disabled {
            background: var(--blue);
        }
    }
    &.orange {
        background: var(--orange);
        &.disabled {
            background: rgb(245, 152, 134);
        }
    }
    p.CTAText {
        text-transform: uppercase;
        text-align: center;
        height: 40px;
        font-weight: bold;
        line-height: 40px;
    }
}

.dcOrange {
    --color: var(--orange);
}

.z-200 {
    z-index: 200;
}

.bottomCTA {
    bottom: 0;
    width: 100%;
    position: fixed;
    color: var(--white) !important;
    display: inline-block;
    background: var(--black);
    cursor: pointer;

    &.sticky {
        position: sticky !important;
    }
    &.disabled {
        background: #ccc;
    }
    &.blue {
        background: var(--blue);
        &.disabled {
            background: #77abd8;
        }
    }
    &.orange {
        background: var(--orange);
        &.disabled {
            background: rgb(245, 152, 134);
        }
    }
    p {
        display: grid;
        align-items: center;
        text-transform: uppercase;
        height: 100%;
        text-align: center;
        font-weight: bold;
        line-height: 40px;
        &.bigtext {
            font-size: 30px;
            margin: 20px;
        }
    }
}

.bg-transparent {
    background: transparent;
    --background: transparent;
}

ion-avatar.editable {
    position: relative;
    height: 3rem;
    .editButton {
        border-radius: 5px;
        position: absolute;
        right: -0.3rem;
        bottom: -0.3rem;
    }
    .editImage {
        width: 2.125rem;
        height: 2.125rem;
        font-size: 0.438rem;
    }
    ion-icon {
        font-size: 4rem;
    }
}

app-profile-photo {
    position: relative;
    display: block;
    height: 48px;
}

#account ion-avatar.big {
    app-profile-photo {
        width: 90px;
        height: 90px;
        border-radius: 50%;
    }
}

.dcplayerList {
    margin: 0 10px;
    ion-row {
        background: var(--white);
        margin: 5px 0;
        ion-avatar {
            display: contents;
        }
        app-profile-photo {
            height: 40px;
            width: 43px;
        }
        ion-col {
            align-content: center;
            display: grid;
        }
        ion-col.text {
            overflow: hidden;
            line-height: 40px;
            white-space: pre;
            text-overflow: ellipsis;
        }
    }
}

.crud-form {
    width: 90%;
    margin: 0 auto;
    ion-input {
        --color: white;
        border-bottom: 1px solid;
        --padding-start: 8px;
    }

    .crud-input {
        background-color: #ffffff2b;
        border: 1px solid #e6e4e4;
        margin-top: 10px;
        padding: 0.75rem;
    }
}

.centerList ion-col {
    text-align: center;
}

.profileheader {
    background: url("./assets/images/backgrounds/bg-orange.jpg") no-repeat center center / cover;
    width: 100%;
    display: inline-block;
    .bigCenterImg {
        display: block;
        ion-avatar {
            width: 100%;
            app-profile-photo {
                border-width: 10px;
                border-style: solid;
                width: 8.438rem !important;
                height: 8.438rem !important;
                border-radius: 50%;
                margin: 0 auto;
            }
        }
    }
}

.warning2 {
    position: relative;
    top: -7px;
    width: 15px;
    left: -21px;
}

.onelineEllipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.green {
    background: #2dd36f;
}

.red {
    background: var(--red);
}

.dcplayerList app-profile-photo img {
    border-radius: 50%;
}

#account .item-inner {
    border: none;
}

ngx-skeleton-loader span {
    margin: 0 !important;
    padding: 0 !important;
}

.centerLogo {
    flex: 1;
    img {
        height: 22px;
    }
}

.fullWidth {
    --inner-padding-end: 0px;
    --padding-start: 0px;
    padding-inline-start: 0px;
    padding-inline-end: 0px;
}

.nav-options.selected {
    background: #dc3b1b;
}

.fullscreen {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
}

// ribbon code
.transition {
    z-index: 100;
    display: none;
    &.visible {
        display: block;
        -webkit-animation: fadeinout 2s linear forwards;
        animation: fadeinout 2s linear forwards;
    }
}

@-webkit-keyframes fadeinout {
    0%,
    100% {
        opacity: 0;
    }
    30%,
    70% {
        opacity: 1;
    }
}

@keyframes fadeinout {
    0%,
    100% {
        opacity: 0;
    }
    30%,
    70% {
        opacity: 1;
    }
}

.panel-title {
    margin: 0;
    font-size: 15px;
}

ion-label {
    font-weight: 400;
}

/* END 2019 CSS */

.list-ios {
    margin: -1px 0 15px;
}

.border-l-white {
    border-left: 0.1em solid var(--white);
}

.border-t-white {
    border-top: 0.1em solid var(--white);
}

.border-l-black {
    border-left: 0.1em solid var(--black);
}

.border-t-black {
    border-top: 0.1em solid var(--black);
}

.halfSectionOne {
    flex-shrink: 0;
    position: relative;
}

.busted {
    background-color: #242424de;
    position: absolute;
    top: 0;
    width: 100%;
    right: 0;
    bottom: 0;
    z-index: 100;
    padding: 20px;
    color: var(--white);
    ion-avatar.medium {
        margin: 20px auto;
        app-profile-photo {
            width: 4rem;
            height: 4rem;
            margin: 0 auto;
            border: 3px solid var(--white);
            .ultimate {
                border: none !important;
            }
            border-radius: 50%;
        }
    }
    h3 {
        margin-top: 50px;
        text-align: center;
    }
}

.back-button-text-md {
    display: none;
}

ion-button.item .item-inner {
    border: none !important;
}

.positive {
    color: #4cb914;
}

.negative {
    color: red;
}

.headItem {
    background: var(--black);
    .label {
        margin: 7px 8px 7px 0 !important;
    }
    h2 {
        color: var(--white);
    }
}

.bottomItem {
    border-top: 1px solid #ccc;
}

.players-1,
.players-2 {
    font-weight: bold;
}

.flag {
    width: 19px;
    height: 12px;
    margin-right: 10px;
}

.bar-buttons .bar-button {
    padding: 10px;
}

.ios .item-button {
    padding: 2px 6px !important;
}

.relative {
    position: relative;
}

.right {
    float: right !important;
}

.noMargin {
    margin: 0px !important;
}

.noPadding {
    padding: 0px !important;
}

.centerColumn {
    justify-content: center;
    display: grid;
    align-items: center;
}

.noInnerMargin .item-inner,
.noInnerMargin .label {
    margin: 0px !important;
}

.noInnerPadding {
    --inner-padding-end: 5px;
    item-inner {
        padding: 0 !important;
    }
}

.overview {
    width: 50%;
    float: left;
}

.overview:not(:first-child) {
    border-left: 1px solid white;
}

.noBorder {
    border: none !important;
}

.overview.leftBorder {
    border-left: 1px solid var(--white);
}

.overview.last {
    width: 49%;
    overflow: hidden;
    float: right;
}

.bold {
    font-weight: bold;
}

ion-reorder {
    margin: 0 5px;
}

.score {
    font-size: 360%;
    font-weight: bold;
    text-align: center;
    width: 100%;
    // height: 60px;
    margin-top: -9px;
}

.mb10 {
    margin-bottom: 10px;
}

.m-0 {
    margin: 0 !important;
}

.p-0 {
    padding: 0 !important;
}

.listHeader {
    color: var(--black);
    border: 0;
    padding: 0 0 10px 12px;
    font-size: 14px;
    font-weight: normal;
    height: 40px;
    line-height: 40px;
    &.white {
        color: var(--white);
    }
}

.dclistHeader {
    color: var(--white);
    border: 0;
    margin: 10px 0 10px;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
}

.goalAmount {
    color: var(--white);
    border: 0;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    line-height: 26px;
    background: var(--black);
    margin: 0 6px;
    border-radius: 7px;
}

.settingsInfoText {
    padding: 0 10px;
    font-size: 16px;
    text-align: center;
    color: var(--white);
}

.divider {
    height: 1px;
    width: 100%;
    background: #dedede;
    margin: 0 0 10px;
}

.throwout {
    font-size: 15px;
    font-weight: bold;
    color: #c75305;
    text-align: center;
    width: 100%;
    margin-top: 7px;
    &.grey {
        color: var(--black);
    }
}

.playerRow {
    background: var(--black);
    padding: 0;
    .wrapperCol:not(:first-child) {
        border-left: 1px solid #828282;
    }
}

.row.players-1 .col {
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: var(--white);
}

.row.players-2 .col {
    font-size: 100%;
    text-align: center;
    color: var(--white);
}

.row.players-2:first-child {
    border-bottom: 1px solid #828282;
}

.singlePlayer {
    background: var(--black);
    color: var(--white);
    border-bottom: 1px solid var(--white);
    ion-col {
        text-align: center;
    }
}

.centerCols ion-col {
    text-align: center;
}

.alignBottom {
    position: absolute;
    width: 100%;
    padding: 0 5px;
    bottom: 0px;
}

.whiteLoading .loading-content {
    color: #ccc;
    text-align: center;
}

.elipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.waiting {
    color: #ffffff75 !important;
    font-weight: normal !important;
}

.waitingLight {
    color: #22222280 !important;
    font-weight: normal !important;
}

.joyride-step__container {
    height: auto !important;
}

/* BLACK KEYBOARD */
.black-keyboard {
    ion-row ion-col.dcKeyboardKey {
        background-color: #333 !important;
        border: 1px;
        border-style: solid;
        border-color: var(--black) !important;
        color: #fefefe !important;
        font-size: 18px;

        justify-content: center;
        align-items: center;
        display: flex;
        ion-button {
            font-size: 18px !important;
            height: 100%;
            width: 100%;
        }

        &.selectedKey {
            background-color: #cccccc !important;
            color: #286ce4 !important;
        }
    }
    ion-row.ion-numeric-keyboard-top-bar,
    ion-row.ion-numeric-keyboard-top-bar ion-col.top-bar-key,
    ion-row ion-col.dcKeyboardKey.control-key {
        background-color: #242424 !important;
    }
}

.dcKeyboard {
    display: none;
    &.visible {
        display: contents;
    }
}

/* END BLACK KEYBOARD */

/* FLAT KEYBOARD */

/* here is how you can remove the border around the keys */

.flat-keyboard {
    ion-row ion-col.key {
        border: none !important;
    }
    ion-grid,
    ion-row.ion-numeric-keyboard-top-bar,
    ion-row.ion-numeric-keyboard-top-bar ion-col.top-bar-key,
    ion-row ion-col.key.key.control-key {
        background-color: var(--white) !important;
    }
}

/* END FLAT KEYBOARD */

// ion-modal::part(backdrop) {
//     --background: transparent !important;
//     background: transparent !important;
//     --backdrop-opacity: var(--ion-backdrop-opacity, 0.7) !important;
// }

// ion-modal {
//     --background: transparent !important;
//     background: transparent !important;
//     --backdrop-opacity: var(--ion-backdrop-opacity, 0.7) !important;
// }

.loading-md .loading-wrapper {
    color: var(--white) !important;
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.cssload-container {
    width: 72px;
    margin: 58px auto;
    font-size: 0;
    position: relative;
    transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    animation: cssload-clockwise 3s linear infinite;
    -o-animation: cssload-clockwise 3s linear infinite;
    -ms-animation: cssload-clockwise 3s linear infinite;
    -webkit-animation: cssload-clockwise 3s linear infinite;
    -moz-animation: cssload-clockwise 3s linear infinite;
}

.cssload-container:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 39px;
    height: 39px;
    border: 6px solid rgb(229, 229, 229);
    border-radius: 100%;
    -o-border-radius: 100%;
    -ms-border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.cssload-container:after {
    position: absolute;
    content: "";
    z-index: -1;
    top: 0;
    right: 0;
    width: 39px;
    height: 39px;
    border: 6px solid rgb(229, 229, 229);
    border-radius: 100%;
    -o-border-radius: 100%;
    -ms-border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.cssload-lt,
.cssload-rt,
.cssload-lb,
.cssload-rb {
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: 39px;
    height: 19px;
    opacity: 1;
}

.cssload-lt:before,
.cssload-rt:before,
.cssload-lb:before,
.cssload-rb:before {
    position: absolute;
    content: "";
    width: 39px;
    height: 39px;
    border-top: 6px solid rgb(255, 102, 0);
    border-right: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid transparent;
    border-radius: 100%;
    -o-border-radius: 100%;
    -ms-border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.cssload-lt {
    margin-right: -6px;
    animation: cssload-lt 1s linear -1000ms infinite;
    -o-animation: cssload-lt 1s linear -1000ms infinite;
    -ms-animation: cssload-lt 1s linear -1000ms infinite;
    -webkit-animation: cssload-lt 1s linear -1000ms infinite;
    -moz-animation: cssload-lt 1s linear -1000ms infinite;
}

.cssload-lt:before {
    top: 0;
    left: 0;
    animation: cssload-not-clockwise 0.5s linear infinite;
    -o-animation: cssload-not-clockwise 0.5s linear infinite;
    -ms-animation: cssload-not-clockwise 0.5s linear infinite;
    -webkit-animation: cssload-not-clockwise 0.5s linear infinite;
    -moz-animation: cssload-not-clockwise 0.5s linear infinite;
}

.cssload-rt {
    animation: cssload-lt 1s linear -500ms infinite;
    -o-animation: cssload-lt 1s linear -500ms infinite;
    -ms-animation: cssload-lt 1s linear -500ms infinite;
    -webkit-animation: cssload-lt 1s linear -500ms infinite;
    -moz-animation: cssload-lt 1s linear -500ms infinite;
}

.cssload-rt:before {
    top: 0;
    right: 0;
    animation: cssload-clockwise 0.5s linear infinite;
    -o-animation: cssload-clockwise 0.5s linear infinite;
    -ms-animation: cssload-clockwise 0.5s linear infinite;
    -webkit-animation: cssload-clockwise 0.5s linear infinite;
    -moz-animation: cssload-clockwise 0.5s linear infinite;
}

.cssload-lb {
    margin-right: -6px;
    animation: cssload-lt 1s linear -750ms infinite;
    -o-animation: cssload-lt 1s linear -750ms infinite;
    -ms-animation: cssload-lt 1s linear -750ms infinite;
    -webkit-animation: cssload-lt 1s linear -750ms infinite;
    -moz-animation: cssload-lt 1s linear -750ms infinite;
}

.cssload-lb:before {
    bottom: 0;
    left: 0;
    animation: cssload-not-clockwise 0.5s linear infinite;
    -o-animation: cssload-not-clockwise 0.5s linear infinite;
    -ms-animation: cssload-not-clockwise 0.5s linear infinite;
    -webkit-animation: cssload-not-clockwise 0.5s linear infinite;
    -moz-animation: cssload-not-clockwise 0.5s linear infinite;
}

.cssload-rb {
    animation: cssload-lt 1s linear -250ms infinite;
    -o-animation: cssload-lt 1s linear -250ms infinite;
    -ms-animation: cssload-lt 1s linear -250ms infinite;
    -webkit-animation: cssload-lt 1s linear -250ms infinite;
    -moz-animation: cssload-lt 1s linear -250ms infinite;
}

.cssload-rb:before {
    bottom: 0;
    right: 0;
    animation: cssload-clockwise 0.5s linear infinite;
    -o-animation: cssload-clockwise 0.5s linear infinite;
    -ms-animation: cssload-clockwise 0.5s linear infinite;
    -webkit-animation: cssload-clockwise 0.5s linear infinite;
    -moz-animation: cssload-clockwise 0.5s linear infinite;
}

@keyframes cssload-clockwise {
    0% {
        transform: rotate(-45deg);
    }
    100% {
        transform: rotate(315deg);
    }
}

@-o-keyframes cssload-clockwise {
    0% {
        -o-transform: rotate(-45deg);
    }
    100% {
        -o-transform: rotate(315deg);
    }
}

@-ms-keyframes cssload-clockwise {
    0% {
        -ms-transform: rotate(-45deg);
    }
    100% {
        -ms-transform: rotate(315deg);
    }
}

@-webkit-keyframes cssload-clockwise {
    0% {
        -webkit-transform: rotate(-45deg);
    }
    100% {
        -webkit-transform: rotate(315deg);
    }
}

@-moz-keyframes cssload-clockwise {
    0% {
        -moz-transform: rotate(-45deg);
    }
    100% {
        -moz-transform: rotate(315deg);
    }
}

@keyframes cssload-not-clockwise {
    0% {
        transform: rotate(45deg);
    }
    100% {
        transform: rotate(-315deg);
    }
}

@-o-keyframes cssload-not-clockwise {
    0% {
        -o-transform: rotate(45deg);
    }
    100% {
        -o-transform: rotate(-315deg);
    }
}

@-ms-keyframes cssload-not-clockwise {
    0% {
        -ms-transform: rotate(45deg);
    }
    100% {
        -ms-transform: rotate(-315deg);
    }
}

@-webkit-keyframes cssload-not-clockwise {
    0% {
        -webkit-transform: rotate(45deg);
    }
    100% {
        -webkit-transform: rotate(-315deg);
    }
}

@-moz-keyframes cssload-not-clockwise {
    0% {
        -moz-transform: rotate(45deg);
    }
    100% {
        -moz-transform: rotate(-315deg);
    }
}

@keyframes cssload-lt {
    0% {
        opacity: 1;
    }
    25% {
        opacity: 1;
    }
    26% {
        opacity: 0;
    }
    75% {
        opacity: 0;
    }
    76% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes cssload-lt {
    0% {
        opacity: 1;
    }
    25% {
        opacity: 1;
    }
    26% {
        opacity: 0;
    }
    75% {
        opacity: 0;
    }
    76% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes cssload-lt {
    0% {
        opacity: 1;
    }
    25% {
        opacity: 1;
    }
    26% {
        opacity: 0;
    }
    75% {
        opacity: 0;
    }
    76% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes cssload-lt {
    0% {
        opacity: 1;
    }
    25% {
        opacity: 1;
    }
    26% {
        opacity: 0;
    }
    75% {
        opacity: 0;
    }
    76% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes cssload-lt {
    0% {
        opacity: 1;
    }
    25% {
        opacity: 1;
    }
    26% {
        opacity: 0;
    }
    75% {
        opacity: 0;
    }
    76% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

//Dartbot loading
.cssload-loader-walk {
    width: 97px;
    height: 39px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
}

.cssload-loader-walk > div {
    content: "";
    width: 19px;
    height: 19px;
    background: rgb(255, 102, 0);
    border-radius: 100%;
    position: absolute;
    animation: cssload-animate 3.2s linear infinite;
    -o-animation: cssload-animate 3.2s linear infinite;
    -ms-animation: cssload-animate 3.2s linear infinite;
    -webkit-animation: cssload-animate 3.2s linear infinite;
    -moz-animation: cssload-animate 3.2s linear infinite;
}

.cssload-loader-walk > div:nth-of-type(1) {
    animation-delay: -0.64s;
    -o-animation-delay: -0.64s;
    -ms-animation-delay: -0.64s;
    -webkit-animation-delay: -0.64s;
    -moz-animation-delay: -0.64s;
}

.cssload-loader-walk > div:nth-of-type(2) {
    animation-delay: -1.28s;
    -o-animation-delay: -1.28s;
    -ms-animation-delay: -1.28s;
    -webkit-animation-delay: -1.28s;
    -moz-animation-delay: -1.28s;
}

.cssload-loader-walk > div:nth-of-type(3) {
    animation-delay: -1.92s;
    -o-animation-delay: -1.92s;
    -ms-animation-delay: -1.92s;
    -webkit-animation-delay: -1.92s;
    -moz-animation-delay: -1.92s;
}

.cssload-loader-walk > div:nth-of-type(4) {
    animation-delay: -2.56s;
    -o-animation-delay: -2.56s;
    -ms-animation-delay: -2.56s;
    -webkit-animation-delay: -2.56s;
    -moz-animation-delay: -2.56s;
}

@keyframes cssload-animate {
    0% {
        left: 97px;
        top: 0;
    }
    80% {
        left: 0;
        top: 0;
    }
    85% {
        left: 0;
        top: -19px;
        width: 19px;
        height: 19px;
    }
    90% {
        width: 39px;
        height: 15px;
    }
    95% {
        left: 97px;
        top: -19px;
        width: 19px;
        height: 19px;
    }
    100% {
        left: 97px;
        top: 0;
    }
}

@-o-keyframes cssload-animate {
    0% {
        left: 97px;
        top: 0;
    }
    80% {
        left: 0;
        top: 0;
    }
    85% {
        left: 0;
        top: -19px;
        width: 19px;
        height: 19px;
    }
    90% {
        width: 39px;
        height: 15px;
    }
    95% {
        left: 97px;
        top: -19px;
        width: 19px;
        height: 19px;
    }
    100% {
        left: 97px;
        top: 0;
    }
}

@-ms-keyframes cssload-animate {
    0% {
        left: 97px;
        top: 0;
    }
    80% {
        left: 0;
        top: 0;
    }
    85% {
        left: 0;
        top: -19px;
        width: 19px;
        height: 19px;
    }
    90% {
        width: 39px;
        height: 15px;
    }
    95% {
        left: 97px;
        top: -19px;
        width: 19px;
        height: 19px;
    }
    100% {
        left: 97px;
        top: 0;
    }
}

@-webkit-keyframes cssload-animate {
    0% {
        left: 97px;
        top: 0;
    }
    80% {
        left: 0;
        top: 0;
    }
    85% {
        left: 0;
        top: -19px;
        width: 19px;
        height: 19px;
    }
    90% {
        width: 39px;
        height: 15px;
    }
    95% {
        left: 97px;
        top: -19px;
        width: 19px;
        height: 19px;
    }
    100% {
        left: 97px;
        top: 0;
    }
}

@-moz-keyframes cssload-animate {
    0% {
        left: 97px;
        top: 0;
    }
    80% {
        left: 0;
        top: 0;
    }
    85% {
        left: 0;
        top: -19px;
        width: 19px;
        height: 19px;
    }
    90% {
        width: 39px;
        height: 15px;
    }
    95% {
        left: 97px;
        top: -19px;
        width: 19px;
        height: 19px;
    }
    100% {
        left: 97px;
        top: 0;
    }
}

// END Dartbot loading
ion-modal::part(content) {
    background: transparent !important;
}

// ion-backdrop {
//     visibility: visible !important;
//     background: rgb(197 197 197 / 40%) !important;
//     backdrop-filter: blur(5px);
// }

ion-modal.breakDialog {
    ion-title {
        padding: 0 15px;
    }
}
ion-modal.breakDialog::part(content) {
    @apply max-w-xl;

    height: 292px !important;
    position: relative;
    width: 90%;
    border-radius: 10px;
}

ion-modal.promptDialog {
    ion-title {
        padding: 0 15px;
    }
}
ion-modal.promptDialog::part(content) {
    @apply max-w-xl;

    height: 250px !important;
    position: relative;
    width: 90%;
    border-radius: 10px;
}

ion-modal.auto-height {
    --height: auto;
}
ion-modal.auto-height::part(content) {
    @apply max-w-xl max-h-[48rem];

    width: 90vw;
    overflow-y: auto;
    border-radius: 10px;
    .ion-page {
        position: relative;
        contain: content;
        max-height: 90vh;
        .modal-content {
            overflow: auto;
        }
    }
}

app-tutorial-dialog.ion-page {
    justify-content: flex-end;
}

ion-modal.ion-fullscreen-modal::part(content) {
    overflow-y: auto;
}

ion-modal.ion-fullscreen-modal.web.from-bottom {
    @apply flex items-end;
}

ion-modal.ion-fullscreen-modal .slide-modal-header {
    @apply pt-safe-offset-5;
}

ion-modal.slide-modal > .ion-page {
    @apply max-h-screen;
}

ion-modal.slide-modal > .ion-page {
    @apply max-h-screen;
}

ion-modal.slide-modal.from-bottom {
    @apply flex items-end;
}

ion-modal.slide-modal::part(content) {
    @apply h-fit;
}

ion-modal.slide-modal.full-width::part(content) {
    @apply w-full;
}

ion-modal.slide-modal.web::part(content) {
    @apply max-w-2xl max-h-[95vh] lg:ml-[304px] rounded-b-lg;
}

ion-modal.comms-modal::part(content) {
    @apply landscape:h-full portrait:h-[75%];
}

ion-modal.comms-modal.from-bottom {
    @apply flex items-end;
}

ion-modal.ion-fullscreen-modal.web::part(content) {
    @apply max-w-2xl max-h-[95vh];
}

ion-modal.featdialog {
    --height: auto;
}
ion-modal.featdialog::part(content) {
    @apply max-w-xl;

    border-radius: 10px;
    .ion-page {
        position: relative;
        contain: content;
        max-height: 85vh;
        .modal-content {
            overflow: auto;
        }
    }
}

.capitalize {
    text-transform: capitalize;
}

/* Captures */

.capture {
    font-family: "Open sans";
    color: var(--white);
    overflow: hidden;
    z-index: -1000;
    position: fixed;
    display: none;
    &.show {
        display: block;
    }
    &.square {
        width: 1080px;
        height: 1080px;
        max-width: 1080px;
        max-height: 1080px;
        .bgimage {
            position: absolute;
            width: 1080px;
            height: 1080px;
            img {
                bottom: 0px;
                position: absolute;
            }
        }
        .cpt_logo {
            width: 280px;
            margin: 25px auto 10px;
        }
    }
    &.landscape {
        width: 1600px;
        max-width: 1600px;
        height: 900px;
        max-height: 900px;
        .bgimage {
            position: absolute;
            width: 1600px;
            height: 900px;
            img {
                bottom: 0px;
                position: absolute;
                height: 880px;
                left: 304px;
                width: 1285px;
            }
        }
        .cpt_logo {
            width: 280px;
            margin: 22px auto;
            img {
                width: 280px;
            }
        }
        .stats_bottom,
        .stats_top {
            padding: 0 125px;
        }
    }
    &.portrait {
        width: 900px;
        max-width: 900px;
        height: 1600px;
        max-height: 1600px;
        .bgimage {
            position: absolute;
            width: 900px;
            height: 1600px;
            img {
                bottom: 0px;
                position: absolute;
            }
        }
        .cpt_logo {
            width: 400px;
            margin: 100px auto 50px;
            img {
                width: 400px;
            }
        }
    }
    &.grey {
        background-color: #2d2d2e;
    }
    &.orange {
        background-color: var(--orange);
    }
    .cpt_title {
        font-size: 46pt;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        font-family: "Open sans";
    }
    .cpt_stats_wrapper {
        padding: 20px 50px;
        text-align: center;
        font-family: "Open sans";
        .stats_top {
            height: auto;
            float: left;
            margin-bottom: 20px;
            width: 100%;
        }
        .stats_bottom {
            width: 100%;
        }
        .cpt_stat {
            text-transform: uppercase;
            width: 100%;
            float: left;
            margin-bottom: 10px;
            font-size: 25pt;
        }
        .cpt_teamsection {
            float: left;
            width: 25%;
            height: 100%;
            margin: 0 0 15px;
            .cpt_stat {
                font-weight: bold;
            }
            .cpt_players {
                app-profile-photo {
                    width: 160px;
                    height: 160px;
                    margin: 0 auto;
                }
                .playername {
                    font-size: 22pt;
                    font-weight: bold;
                    margin-top: 15px;
                    display: block;
                    text-align: center;
                }
            }
        }
        .cpt_generalsection {
            float: left;
            width: 50%;
            height: 100%;
            .cpt_main {
                padding-top: 10px;
                span {
                    width: 100%;
                    float: left;
                    font-weight: bold;
                }
                .type {
                    font-size: 22pt;
                }
                .result {
                    font-size: 55pt;
                    margin: 10px 0;
                }
                .date {
                    font-size: 22pt;
                }
            }
        }
        .cpt_cta {
            .cpt_cta_row {
                text-align: center;
                width: 100%;
                float: left;
                .images {
                    margin: 0 auto;
                    width: 330px;
                    img {
                        width: 150px;
                        margin-right: 15px;
                        float: left;
                        margin-bottom: 10px;
                    }
                }
                span {
                    margin-right: 10px;
                    font-size: 26pt;
                    font-weight: bold;
                    text-transform: uppercase;
                    &.orange {
                        color: var(--orange);
                    }
                    &.grey {
                        color: #2d2d2e;
                    }
                }
            }
        }
    }
}

.dcSegment:not(:last-child) {
    border-right: 1px solid var(--white);
}

.in-game-chat-container {
    background: url("assets/images/backgrounds/bg-grey.jpg") no-repeat center center / cover;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    z-index: 120;
    position: sticky;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 6px;
    display: flex;
    flex-direction: column;

    &.end-screen {
        width: 98%;
        margin: auto;
        position: absolute;
    }

    .chat-container {
        padding: 0.5rem 0.75rem 0.75rem 0.75rem;
        height: calc(100% - 66px);
        overflow-y: scroll;
    }

    .chat-segment {
        height: 100%;
        width: 100%;
        color: white;
        overflow-y: scroll;
        display: none;

        &.visible {
            display: block;
        }

        .grouped-chat-message {
            display: flex;
            flex-direction: column;

            .sender {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 0.9em;
                color: lightgray;
                font-weight: bold;
                padding: 0 0.75rem;
                text-transform: capitalize;

                &.right {
                    text-align: right;
                }
            }

            .chat-bubble {
                width: auto;
                white-space: nowrap;
                // margin: 4px auto;
                background: #3f3f46;
                padding: 6px 8px;
                text-align: center;
                font-weight: 900;
                color: #fff;
                font-family: arial;
                position: relative;
                display: flex;
                align-items: center;

                &.bubble-right {
                    width: fit-content;
                    float: right;
                    white-space: initial;
                    text-align: right;
                }

                &.bubble-right:before {
                    content: "";
                    width: 0px;
                    height: 0px;
                    position: absolute;
                    border-left: 10px solid #3f3f46;
                    border-right: 10px solid transparent;
                    border-top: 10px solid #3f3f46;
                    border-bottom: 10px solid transparent;
                    right: -20px;
                    top: 6px;
                }

                &.bubble-left {
                    width: fit-content;
                    float: left;
                    white-space: initial;
                    text-align: left;
                }

                &.bubble-left:before {
                    content: "";
                    width: 0px;
                    height: 0px;
                    position: absolute;
                    border-left: 10px solid transparent;
                    border-right: 10px solid #3f3f46;
                    border-top: 10px solid #3f3f46;
                    border-bottom: 10px solid transparent;
                    left: -19px;
                    top: 6px;
                }
            }
        }

        .photo-with-chats {
            margin-top: 0.25rem;
            align-items: start;

            .chats {
                display: flex;
                flex-direction: column;
                margin-top: 0.25rem;
                width: 65%;
                margin: 0 1.15rem;

                > * + * {
                    margin-top: 0.5rem;
                }

                .emoji {
                    font-size: 2em;
                }

                &.right {
                    align-items: flex-end;
                }
            }

            &.left {
                justify-content: start;
            }

            &.right {
                justify-content: end;
                flex-direction: row-reverse;
            }
        }
    }

    .chat-header {
        font-family: "Bebas Neue";
        color: var(--white);
        background: var(--black);
        padding: 10px 5px;
        margin-bottom: 10px;
        h2 {
            margin: 0;
            font-family: "Bebas Neue";
        }
    }

    .chat-footer {
        display: flex;
        align-items: center;
        height: 66px;
        width: 100%;
        padding: 0.5rem 1rem;
        background-color: rgba(0, 0, 0, 0.25);
    }
}

.smart-device-container {
    > * + * {
        margin-top: 0.5rem;
    }

    .network-container {
        border-radius: 7px;
        background: #00000040;
        padding: 0 0.5rem 0.5rem 0.5rem;

        .network-header {
            display: flex;
            justify-content: space-between;
            padding: 1rem 0rem 0.5rem 0.75rem;
        }

        .device-list {
            margin-top: 0.5rem;

            > * + * {
                margin-top: 0.25rem;
            }

            .device-container {
                border-radius: 7px;
                background: #00000040;

                > * + * {
                    margin-top: 0.25rem;
                }

                .device-name {
                    margin: 0;
                    font-size: 15px;
                }

                .device-guid {
                    margin: 6px 0 0 0;
                    font-size: 10px;
                }

                .device-ip {
                    margin: 5px 0 0;
                    font-size: 11px;
                }
            }
        }
    }
}

ion-footer ion-toolbar {
    --padding-bottom: 0;
}

.hide {
    height: 0 !important;
    width: 0 !important;
    display: block;
}

.visibility-hidden {
    visibility: hidden;
}

ion-toolbar {
    position: relative !important;

    ion-title {
        &.md {
            position: absolute;
            width: 100%;
            left: 0;
            top: 0;
            bottom: 0;
        }
    }
}

.commsOptions ion-badge {
    padding: 6px;
    font-size: 15px;
    margin: 3px 6px;
    background: var(--blue-highlight);
    color: var(--white);
}

.IncomingCommsSection {
    position: absolute;
    bottom: -20px;
    width: 100%;
    z-index: 200;
    .commsOptions {
        padding: 5px 10px;
        width: fit-content;
        justify-content: center;
        align-items: center;
        margin: 0 auto 10px;
        border-radius: 5px;
        background: var(--blue-highlight);
        position: relative;
        color: var(--white);
        display: flex;
        .name {
            float: left;
            font-weight: bold;
            margin: 0 15px 0 10px;
            font-size: 12px;
            line-height: 20px;
        }
        .commText {
            float: left;
            margin-right: 10px;
        }
        &:after {
            content: "";
            position: absolute;
            top: 0;
            width: 0;
            height: 0;
            border: 9px solid transparent;
            border-bottom-color: var(--blue-highlight);
            border-top: 0;
            border-right: 0;
            right: 16px;
            margin-top: -9px;
        }
    }
}

.DCBackdrop {
    display: none;
    background: #333333d1;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 110;
    -webkit-animation: fadein 0.5s linear;
    animation: fadein 0.5s linear;
    &.visible {
        display: block;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
}

/* in-flight clone */

.gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
    pointer-events: none;
}

/* high-performance display:none; helper */

.gu-hide {
    left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */

.gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}

/* added to the source element while its mirror is dragged */

.gu-transit {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
}

// End of Dragula

.align-self-center {
    align-self: center;
}

.align-self-end {
    align-self: flex-end;
}

.flex-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
}

.text-tiny {
    font-size: 11px !important;
}

.text-small {
    font-size: 12px !important;
}

.text-medium {
    font-size: 0.875rem !important;
}

.text-normal {
    font-size: 16px !important;
}

.radius {
    border-radius: 8px !important;
}

.divider {
    background: var(--ion-color-light);
    margin: 8px 0;
    height: 1px;
    width: 100%;
}

.bold {
    font-weight: bold;
}

.input-container {
    background-color: #ffffff2b;
    border: 1px solid #e6e4e4;
    border-radius: 0;
    margin-top: 10px;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
}

.input-container.white-placeholder::placeholder {
    color: white;
}

.game-label-container {
    &.with-border {
        margin: 0.75rem;
        border-radius: 0.5rem;
        padding: 1rem 0.5rem;
        background: #00000015;
    }

    > * + * {
        margin-top: 0.5rem;
    }

    .game-label-large {
        padding: 0.25rem 0.5rem;
        align-items: center;

        .label-title {
            height: fit-content;
            font-weight: bold;
            border-radius: 4px;
            padding: 0.5rem;
            cursor: pointer;
        }
    }
}

.online-pause-container {
    padding-top: 1rem;
    height: 100%;
    background: url("assets/images/backgrounds/bg-grey.jpg") no-repeat center center / cover;
    // background-color: #242424de;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 110;
    color: white;
    display: flex;
    justify-content: center;
    .pause-hover {
        background-color: rgba(0, 0, 0, 0.25);
        width: 100%;
        height: fit-content;
        margin: 1rem 1.5rem;
        padding: 0.5rem;
        border-radius: 6px !important;
    }
    h4 {
        font-family: "Bebas Neue";
        font-size: 2.5em;
        text-align: center;
        color: white;
    }
    p {
        font-size: 1em;
    }
    .pause-timer {
        text-align: center;
        font-size: 2.5em;
        color: white;
    }
    .submit-btn {
        margin-top: 0.5rem;
        font-size: 1.1em;
        --background: var(--orange);
        height: 40px;
        --border-radius: 4px !important;
    }
}

app-profile-photo {
    &.animated-float {
        border-radius: 50%;
        transform: translatey(0px);
        animation: float 3s ease-in-out infinite;
    }
}

@keyframes float {
    0% {
        box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
        transform: translatey(0px);
    }
    50% {
        box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
        transform: translatey(-10px);
    }
    100% {
        box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
        transform: translatey(0px);
    }
}

.end-game-rematch-overlay {
    background: rgba(0, 0, 0, 0.4) !important;
    backdrop-filter: blur(5px);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .rematch-container {
        height: 100%;
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .rematch-opponent {
            display: flex;
            flex-direction: column;
            align-items: center;

            .playername {
                margin-top: 0.5rem;
                font-size: 1.35em;
                font-weight: 900;
                text-align: center;
            }
        }
    }
}

.dimentional-button {
    background: hsl(340deg 100% 32%);
    border-radius: 12px;
    border: none;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;

    &.gray {
        background: #9d9d9d;
    }

    &.blue {
        background: #285ab2;
    }

    &.red {
        background: #b83243;
    }

    .front {
        display: grid;
        align-items: center;
        height: 100%;
        padding: 16px 8px;
        text-align: center;
        border-radius: 12px;
        font-size: 1.25rem;
        font-weight: 600;
        background: hsl(345deg 100% 47%);
        color: white;
        transform: translateY(-6px);
        text-transform: uppercase;

        &.gray {
            color: #5b5b59;
            background: #c6c6c6;
        }

        &.blue {
            color: white;
            background: #3a7fff;
        }

        &.red {
            color: white;
            background: #eb445a;
        }
    }
}

.stream-paused-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-family: "Bebas Neue" !important;
    font-size: 3em;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 10;
}

.dcSegmentedInputWrapper {
    font-family: "Bebas Neue";
    margin: 10px 15px;
    display: grid;
    h2 {
        text-align: center;
        margin: 0 auto 5px;
        width: 100%;
    }
    .segmentContainer {
        margin: 0 auto;
    }
    .inputSegment {
        width: 31px;
        background-color: #ccc;
        float: left;
        margin: 0 4px;
        border-radius: 5px;
        padding-bottom: 7px;
        .character {
            text-align: center;
            line-height: 25px;
            margin-top: 10px;
            font-size: 26px;
            color: #444;
        }
    }
}
