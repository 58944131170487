/* backgrounds */
.bg-white-pattern {
    @apply dark:bg-gray-pattern;
}

.bg-gray-pattern {
    @apply dark:bg-white-pattern;
}

.bg-white {
    @apply dark:bg-black;
}

.bg-black {
    @apply dark:bg-white;
}

.bg-black.text-white {
    @apply dark:bg-white dark:text-black;
}

.bg-neutral-50 {
    @apply dark:bg-neutral-950;
}

.bg-neutral-100 {
    @apply dark:bg-neutral-900;
}

.bg-neutral-200 {
    @apply dark:bg-neutral-800;
}

.bg-neutral-300 {
    @apply dark:bg-neutral-700;
}

.bg-neutral-700 {
    @apply dark:bg-neutral-300;
}

.bg-neutral-800 {
    @apply dark:bg-neutral-300;
}

.bg-neutral-900 {
    @apply dark:bg-neutral-800;
}

.bg-gray-50 {
    @apply dark:bg-neutral-950;
}

.ion-bg-white-header {
    @apply dark:ion-bg-black-header;
}

.ion-bg-white {
    @apply dark:ion-bg-black;
}

/* shadows */
.dark .global-lobby-shadow {
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.6);
}

/* texts */
.ion-text-black {
    @apply dark:ion-text-white;
}

.ion-text-white {
    @apply dark:ion-text-black;
}

.ion-text-black {
    @apply dark:ion-text-white;
}

.text-white {
    @apply dark:text-black;
}

.text-black {
    @apply dark:text-white;
}

.text-yellow {
    @apply dark:text-yellow-accent;
}

.text-neutral-100 {
    @apply dark:text-neutral-900;
}

.text-neutral-200 {
    @apply dark:text-neutral-800;
}

.text-neutral-300 {
    @apply dark:text-neutral-700;
}

.text-neutral-400 {
    @apply dark:text-neutral-200;
}

.text-neutral-600 {
    @apply dark:text-neutral-200;
}

.text-neutral-700 {
    @apply dark:text-neutral-300;
}

.text-neutral-800 {
    @apply dark:text-neutral-200;
}

.text-neutral-900 {
    @apply dark:text-neutral-100;
}

/* borders */
.border-black {
    @apply dark:border-white;
}

.border-white {
    @apply dark:border-black;
}

.border-b-white {
    @apply dark:border-b-black;
}

.divide-white {
    @apply dark:divide-gray-500;
}

.border-neutral-50 {
    @apply dark:border-neutral-950;
}

.border-neutral-100 {
    @apply dark:border-neutral-900;
}

.border-neutral-200 {
    @apply dark:border-neutral-800;
}

.border-neutral-300 {
    @apply dark:border-neutral-700;
}

.border-neutral-400 {
    @apply dark:border-neutral-600;
}

.border-neutral-800 {
    @apply dark:border-neutral-200;
}

.border-neutral-900 {
    @apply dark:border-neutral-100;
}

/* hovers */
.hover\:bg-white-accent:hover {
    @apply dark:hover:bg-black-accent;
}

.hover\:bg-black-accent:hover {
    @apply dark:hover:bg-white-accent;
}

.hover\:bg-neutral-50:hover {
    @apply dark:hover:bg-neutral-950;
}

.hover\:bg-neutral-100:hover {
    @apply dark:hover:bg-neutral-900;
}

.hover\:bg-neutral-200:hover {
    @apply dark:hover:bg-neutral-800;
}

.hover\:bg-neutral-900:hover {
    @apply dark:hover:bg-neutral-100;
}

.hover\:bg-neutral-800:hover {
    @apply dark:hover:bg-neutral-200;
}

/* divide */
.divide-neutral-200 {
    @apply dark:divide-neutral-800;
}
